import { environment } from '../../../../environments/environment';

export const imageResoures = {
  logo: `${environment.deployAssetsPath}/assets/images/landing/logo.svg`,
  headerImg: `${environment.deployAssetsPath}/assets/images/landing/macbook.png`,
  technologyImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/angular-logo.png`,
  bootstrapStackImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/bootstrap-stack.png`,
  routerImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/angular-logo.png`,
  reactiveFormImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/reactive-forms.png`,
  sassImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/sass.png`,
  matUiImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/mat_ui.png`,
  reduxImg: `${environment.deployAssetsPath}/assets/images/landing/technologies/redux.png`,
  designeCodeImg: `${environment.deployAssetsPath}/assets/images/landing/design_and_code.svg`,
  feedbackImg: `${environment.deployAssetsPath}/assets/images/landing/feedback_macbook.png`,
  rightBgImg: `${environment.deployAssetsPath}/assets/images/landing/right_bg.png`,
  leftBgImg: `${environment.deployAssetsPath}/assets/images/landing/left_bg.png`,
  adminBookImg: `${environment.deployAssetsPath}/assets/images/landing/admin_book.png`,
  codeExample: `${environment.deployAssetsPath}/assets/images/landing/code_example.png`,
  feature01Img: `${environment.deployAssetsPath}/assets/images/landing/features/01_new.png`,
  feature02Img: `${environment.deployAssetsPath}/assets/images/landing/features/02_new.png`,
  feature03Img: `${environment.deployAssetsPath}/assets/images/landing/features/03_new.png`,
  feature04Img: `${environment.deployAssetsPath}/assets/images/landing/features/04_new.png`,
  feature05Img: `${environment.deployAssetsPath}/assets/images/landing/features/05_new.png`,
  feature06Img: `${environment.deployAssetsPath}/assets/images/landing/features/06_new.png`,
  feature07Img: `${environment.deployAssetsPath}/assets/images/landing/features/07_new.png`,
  feature08Img: `${environment.deployAssetsPath}/assets/images/landing/features/08_new.png`,
  pointsLgImg: `${environment.deployAssetsPath}/assets/images/landing/decor/points-lg.png`,
  pointsMdImg: `${environment.deployAssetsPath}/assets/images/landing/decor/points-md.png`,
  pointsSmImg: `${environment.deployAssetsPath}/assets/images/landing/decor/points-sm.png`,
  sinLgImg: `${environment.deployAssetsPath}/assets/images/landing/decor/sin-lg.png`,
  sinMdImg: `${environment.deployAssetsPath}/assets/images/landing/decor/sin-md.png`,
  plusLgImg: `${environment.deployAssetsPath}/assets/images/landing/decor/plus-lg.png`,
  plusMdImg: `${environment.deployAssetsPath}/assets/images/landing/decor/plus-md.png`,
  plusSmImg: `${environment.deployAssetsPath}/assets/images/landing/decor/plus-sm.png`,
  pointImg: `${environment.deployAssetsPath}/assets/images/landing/decor/point.png`,
  footerImg: `${environment.deployAssetsPath}/assets/images/landing/footer_bg.png`,
};
